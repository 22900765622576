import React from "react"
import { RiStarSFill } from "react-icons/ri"

const Stars = ({ Rating }) => {
  return (
    <div className="stars">
      Rating:
      {Rating == "5" && (
        <span>
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
        </span>
      )}
      {Rating == "4" && (
        <span>
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
          <RiStarSFill className="star" color="#FBEEC1" fontSize="1.5em" />
        </span>
      )}
    </div>
  )
}

export default Stars
